import React from 'react';
import { BannerWrap } from './banner.stc'


const Banner = () => {

    return (
        <BannerWrap>

        </BannerWrap>
    )
}

export default Banner;