import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Social from '../data/images/konya-furkan-acar-mimar.jpg';

function SEO({
    description,
    lang,
    meta,
    keywords,
    title,
    img,
    type,
    createdAt,
    updatedAt,
    url,
}) {
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription =
                    description || data.site.siteMetadata.description;
                return (
                    <Helmet
                        htmlAttributes={{
                            lang,
                        }}
                        title={title}
                        titleTemplate={`%s`}
                        meta={[
                            {
                                property: `og:title`,
                                content: title,
                            },
                            {
                                property: `og:url`,
                                content: url,
                            },
                            {
                                name: 'google-site-verification',
                                content: '7PUOlXCF_xAPSJS8PbJb0tGO-6sP5wdPKKn03vfhbR8'
                            },
                            {
                                name: 'robots',
                                content: 'index, follow'
                            },
                            {
                                name: 'googlebot',
                                content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
                            },
                            {
                                name: 'bingbot',
                                content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
                            },
                            {
                                name: `description`,
                                content: metaDescription,
                            },
                            {
                                property: `og:image`,
                                content: img || Social,
                            },
                            {
                                property: `og:type`,
                                content: type || `website`,
                            },
                            {
                                property: `og:updated_time`,
                                content: updatedAt,
                            },
                            {
                                property: `og:published_time`,
                                content: createdAt,
                            },
                            {
                                property: `og:description`,
                                content: metaDescription,
                            },
                            {
                                name: `twitter:card`,
                                content: `summary_large_image`,
                            },
                            {
                                name: `twitter:creator`,
                                content: data.site.siteMetadata.author,
                            },
                            {
                                name: `twitter:title`,
                                content: title,
                            },
                            {
                                name: `twitter:description`,
                                content: metaDescription,
                            },
                            {
                                name: `twitter:image`,
                                content: img || Social,
                            },
                        ]
                            .concat(
                                keywords.length > 0
                                    ? {
                                        name: `keywords`,
                                        content: keywords.join(`, `),
                                    }
                                    : []
                            )
                            .concat(meta)}
                    />
                );
            }}
        />
    );
}

SEO.defaultProps = {
    lang: `tr-TR`,
    meta: [],
    keywords: [],
};

SEO.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    img: PropTypes.string,
    type: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        author
        description
        image
        siteUrl
        title
        twitterUsername
      }
    }
  }
`;
